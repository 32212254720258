<template>
  <div id="detalle-resumen">
    <template v-if="solicitud">
      <div class="container">
        <h2 class="text-center d-block">Resumen del Modelo</h2>
        <h5 class="text-center d-block">Solicitud: {{ solicitud.ServiciosOriginacion.id }}</h5>
        <h6 class="text-center d-block">Tipo Solicitud: {{ solicitud.tipo_solicitud }}</h6>
        <div class="col-md-12">
          <div class="row">
            <div class="negocio col-md-12">
              <div class="block-cabezal">
                <h4> Descripción del Negocio </h4>
              </div>
              <div class="container content-tabla">
                <div class="generales-data">
                  <span class="heanding-mobile">
                    ¿Es empleado?
                  </span>
                  <span class="col text-right">
                    {{ employeeStatus(solicitud.ServiciosOriginacion.es_empleado) }}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Giro del negocio
                  </span>
                  <span class="col text-right">
                    {{ solicitud.ServiciosOriginacion.giro_negocio.segundo_nivel }}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Descripción del negocio
                  </span>
                  <span class="col text-right">
                    {{ solicitud.ServiciosOriginacion.descripcion_giro_negocio }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="negocio col-md-12">
              <div class="block-cabezal">
                <h4> Políticas </h4>
              </div>
              <div class="container content-tabla">
                <div class="generales-data">
                  <span class="heanding-mobile">
                    ¿Cumple políticas?
                  </span>
                  <span class="col text-right">
                    {{ politicasStatus(solicitud.ServiciosOriginacion.cumple_politicas) }}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Descripción políticas
                  </span>
                  <span class="col text-right">
                    {{ solicitud.ServiciosOriginacion.descripcion_politicas }}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Observaciones Comercial
                  </span>
                  <span class="col text-right">
                    {{ solicitud.ServiciosOriginacion.observaciones_comercial }}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Observaciones Originación
                  </span>
                  <span class="col text-right">
                    {{ solicitud.ServiciosOriginacion.observaciones_originacion }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class='colateral col-md-6'>
              <div class="block-cabezal">
                <h4> Colateral </h4>
              </div>
              <div class="container content-tabla">
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Entidad
                  </span>
                  <span class="col text-right">
                    {{ solicitud.garantias[0].estado }}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Valor propiedad
                  </span>
                  <span class="col text-right">
                    ${{ formatPrice(solicitud.opinionValor.valor) }}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Comercialización
                  </span>
                  <span class="col text-right">
                    {{ solicitud.opinionValor.tiempo_comercializacion }}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Evaluación sobre 5
                  </span>
                  <span class="col text-right">
                    {{ formatRounded(solicitud.opinionValor.score) }}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Aforo
                  </span>
                  <span class="col text-right">
                    {{ solicitud.variablesUnykoo.aforo }}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Tasa propuesta
                  </span>
                  <span class="col text-right">
                    {{ solicitud.variablesUnykoo.tasaPropuesta }}
                  </span>
                </div>
              </div>
            </div>
            
            <div class='capacidad col-md-6'>
              <div class="block-cabezal">
                <h4> Capacidad </h4>
              </div>
              <div class="text-center">
                <div id="chartCapacidad"></div>
              </div>
              <div class="container content-tabla">
                <div class="generales-data">
                <span class="heanding-mobile">
                  Monto solicitado
                </span>
                <span class="col text-right">
                  ${{ formatPrice(solicitud.ServiciosOriginacion['importe_solicitado']) }}
                </span>
              </div>
              <div class="generales-data">
                <span class="heanding-mobile">
                  Pago requerido
                </span>
                <span class="col text-right">
                  ${{ formatPrice(solicitud.variablesUnykoo.BpbDeudaMensual) }}
                </span>
              </div>
              <div class="generales-data">
                <span class="heanding-mobile">
                  Ingresos
                </span>
                <span class="col text-right">
                  ${{ formatPrice(solicitud.Ocr.ingresosTotal) }}
                </span>
              </div>
              <div class="generales-data">
                <span class="heanding-mobile">
                  Monto propuesto
                </span>
                <span class="col text-right">
                  ${{ formatPrice(solicitud.Ocr.montoPropuestoTotal) }}
                </span>
              </div>
              <div class="generales-data">
                <span class="heanding-mobile">
                  DTI
                </span>
                <span class="col text-right">
                  {{ solicitud.variablesUnykoo.calculoCapacidadPago }}%
                </span>
              </div>
              </div>
            </div>
          </div>
          
          <div class="row">
            <div class='comportamiento col-md-6'>
              <div class="block-cabezal">
                <h4> Comportamiento </h4>
              </div>
              <div class="container content-tabla">
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Cuentas abiertas
                  </span>
                  <span class="col text-right">
                    {{ solicitud.variablesUnykoo.TotalCuentasAbiertas }}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Cuentas MOP 01
                  </span>
                  <span class="col text-right">
                    {{ solicitud.variablesUnykoo.TotalCuentasMop01}}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Cuentas atrasos menores
                  </span>
                  <span class="col text-right">
                    {{ solicitud.variablesUnykoo.TotalCuentasAtrasoMenor }}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Cuentas MOP 96
                  </span>
                  <span class="col text-right">
                    {{ solicitud.Scoring.buro[0]["cuentas_mop_96"] }}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Cuentas MOP 97
                  </span>
                  <span class="col text-right">
                    {{ solicitud.Scoring.buro[0]["cuentas_mop_97"] }}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Total créditos máximos pagos fijos
                  </span>
                  <span class="col text-right">
                    ${{ formatPrice(solicitud.Scoring.buro[0]["total_creditos_maximos_pagos_fijos"]) }}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Total saldos actuales pagos fijos
                  </span>
                  <span class="col text-right">
                    ${{ formatPrice(solicitud.Scoring.buro[0]["total_saldos_actuales_pagos_fijos"])}}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Score de buró
                  </span>
                  <span class="col text-right">
                    {{ solicitud.Scoring.buro[0].score }}
                  </span>
                </div>
              </div>
            </div>

            <!-- AVATAR DESHABILITADO --->
            <!--
            <div class='avatar col-md-6'>
              <div class="block-cabezal">
                <h4> AVATAR </h4>
              </div>
              <div class="container content-tabla">
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Probabilidad de fraude
                  </span>
                  <span class="col text-right">
                    {{ solicitud.Scoring.avatar['probabilidad_fraude'] }}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Calificación
                  </span>
                  <span class="col text-right">
                    {{ variablesAvatar(solicitud.Scoring.avatar['probabilidad_fraude']) }}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Probabilidad de información cuantitativa falsa
                  </span>
                  <span class="col text-right">
                    {{ solicitud.Scoring.avatar['probabilidad_informacion_cuantitativa_falsa'] }}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Calificación
                  </span>
                  <span class="col text-right">
                    {{ variablesAvatar(solicitud.Scoring.avatar['probabilidad_informacion_cuantitativa_falsa']) }}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Probabilidad de información cualitativa falsa
                  </span>
                  <span class="col text-right">
                    {{ solicitud.Scoring.avatar['probabilidad_informacion_cualitativa_falsa'] }}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Calificación
                  </span>
                  <span class="col text-right">
                    {{ variablesAvatar(solicitud.Scoring.avatar['probabilidad_informacion_cualitativa_falsa']) }}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Comportamiento económico
                  </span>
                  <span class="col text-right">
                    {{ solicitud.Scoring.avatar['comportamiento_economico'] }}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Calificación
                  </span>
                  <span class="col text-right">
                    {{ variablesAvatar(solicitud.Scoring.avatar['comportamiento_economico']) }}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Riesgo persona
                  </span>
                  <span class="col text-right">
                    {{ solicitud.Scoring.avatar['riesgo_persona'] }}
                  </span>
                </div>
                <div class="generales-data">
                  <span class="heanding-mobile">
                    Calificación
                  </span>
                  <span class="col text-right">
                    {{ riesgoAvatar }}
                  </span>
                </div>
              </div>
            </div>
            -->
          </div>
        </div>
        
      </div>
      <div v-if="cesion_creditos">
        <VotacionCesionCredito/>
      </div>
      <div v-else>
        <Votacion @update="obtener_solicitud" />
      </div>
    </template>
  </div>
</template>

<script>
import scoring from '@/apps/comite/api/scoring'
import comite from '@/apps/comite/api/comite'
import gchart from '@/helpers/gcharts'
import Votacion from '@/apps/comite/pages/Votacion'
import VotacionCesionCredito from '@/apps/comite/pages/VotacionCesionCreditos'

import api from '@/apps/comite/api/comite'

export default {
  name: 'resumen-modelo',
  components: {
    Votacion,
    VotacionCesionCredito,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      capacidadData: [] ,
      capacidadOptions: {
        chart: {
          title: 'Capacidad',
          subtitle: ' ',
          hAxis: {
            title: 'Balance',
            titleTextStyle: {
              color: '#FF0000',            
            },
            
            slantedText:true,
            slantedTextAngle:45,
            
          },
        }
      },
      showChart: false,
      solicitud: null,
      cesion_creditos: false,
    }
  },

  created: function() {
    this.solicitud = this.$store.state.solicitud;    
  },
  
  async mounted() {
    await this.obtener_solicitud();

    var menu = [{
        title: 'Comité'
        ,comite: 'Solicitudes'
        ,comite_historico: 'Histórico'
      }
      ,{
        title: 'Solicitud'
        ,comite_resumen_modelo: [
          'Resumen modelo',
          {
            id: this.solicitud.solicitud.id
          }
        ]
        ,comite_garantia: 'Garantía'
        ,comite_comportamiento: 'Comportamiento'
        ,comite_capacidad_pago: 'Capacidad de pago'
        ,comite_decision_modelo: 'Decisión modelo'
        /**-------- OPEN BANKING DESHABILITADO ------------**/
        //,open_banking: 'Open Banking'
      }
    ]

    if(this.$auth.getUser().roles.comite_votar_creditos_cedidos){
      delete menu[0].comite_historico;
    }

    this.$store.commit('setMenu', menu);

    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },

  computed: {
    /**-------- AVATAR DESHABILITADO ------------**/
    /**riesgoAvatar(){
      if (!this.solicitud) 
        return 'NA';

      const riesgoPersona = this.solicitud.Scoring.avatar['riesgo_persona']
      let resultado;
                  
      if (riesgoPersona <= 1 && riesgoPersona !== null){
        resultado = "Bueno";
      } else if(riesgoPersona >= 1.1 && riesgoPersona <= 3.1) {
        resultado = "Regular";
      } else if(riesgoPersona > 3.1) {
        resultado = "Malo";
      } return resultado;
    },**/
    getChartData(){
      return this.capacidadData
    }
  },
  
  methods: {
    async obtener_solicitud() {
      try {
        this.solicitud = this.$store.state.solicitud;
        if (!this.solicitud || (this.solicitud && this.solicitud.solicitud && this.solicitud.solicitud.id != this.$route.params.id)) {
          this.solicitud = (await api.solicitud(this.$route.params.id)).data;
          this.$store.commit('set', { solicitud: this.solicitud });
        }

        this.$log.info('solicitud', this.solicitud);

        await gchart();
        this.$log.info('cargamos google charts');

        this.chartData();  

        if(this.$auth.getUser().roles.comite_ver_creditos_cedidos){
          this.cesion_creditos = true
        }
      }catch(e) {
        this.$log.info('Error', e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    formatPrice(value) {
      let val = (value/1).toFixed(0).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    
     formatRounded(value) {
        let val = (value/1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "")
    },
    /**-------- AVATAR DESHABILITADO ------------**/
    /**variablesAvatar(resultadoProspecto){
      resultadoProspecto=parseFloat(resultadoProspecto);
      
      let resultado;  
      
      if (resultadoProspecto <= 4 && resultadoProspecto !== null){
        resultado = "Bueno";
      } else if(resultadoProspecto >= 4.1 && resultadoProspecto <= 6.5) {
        resultado = "Regular";
      } else if(resultadoProspecto > 6.5){
        resultado = "Malo";
      } return resultado;
    },**/

    employeeStatus(){
      const isEmployee = this.solicitud.ServiciosOriginacion.es_empleado
      let employee;

      if (isEmployee == 1) {
        employee = "Sí";
      } else if (isEmployee == 0){
        employee = "No";
      } else if (isEmployee == null){
        employee = "Sin información alimentada en sistema de originación"
      } return employee;
    },

    politicasStatus(){
      const cumplePolitica = this.solicitud.ServiciosOriginacion.cumple_politicas
      let politica;

      if (cumplePolitica == 1) {
        politica = "Sí";
      } else if (cumplePolitica == 0){
        politica = "No";
      } else if (cumplePolitica == null){
        politica = "Sin información alimentada en sistema de originación"
      } return politica;
    },
    
    async chartData() {
      let capacidadData = await scoring.datos_chart(this.solicitud.solicitud.id);
      this.$log.info('capacidadData', capacidadData);
      capacidadData = capacidadData.data;
      
      google.charts.load('current', {packages: ['corechart', 'bar']});
      google.charts.setOnLoadCallback(() => {
      
      let chartRows = [
          ['Fechas','Depósitos','Retiros', 'Requerido']
        ];
        
        if (capacidadData && capacidadData.Statement_Periods && capacidadData.Statement_Periods.Beginning_Date) {
          let numItems = capacidadData.Statement_Periods.Beginning_Date.length;
          for(let i=0; i<numItems; i++) {
          chartRows.push([capacidadData.Statement_Periods.Beginning_Date[i], (capacidadData.Statement_Periods.Deposit_Sum[i]),
          (capacidadData.Statement_Periods.Withdrawal_Sum[i]*-1), parseFloat(this.solicitud.variablesUnykoo.BpbDeudaMensual) ]);
          }
        
          // console.log(chartRows);
          
          let chart = google.visualization.arrayToDataTable(chartRows);

          let options = {
            vAxis: {
              title: 'Montos'
            },
            hAxis: {
              title: 'Fechas'
            },
            seriesType: 'bars'
            ,series: {2: {type: 'line'}}
          };

          let objChart = new google.visualization.ComboChart(document.getElementById('chartCapacidad'));
          objChart.draw(chart, options);
        }

      });
    },

    formatRounded(value) {
        let val = (value/1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "")
    },
  },

}

</script>

<style lang="scss" scoped>
  #detalle-resumen {
    background-color: $white;
    // height: 100%; 
    margin-bottom: 100px;
    
    .block-cabezal {
      background-color: $color-blue;
      padding: 1rem;
      margin: 1rem 0;
      
      h4 {
        color: $white;
      }
      
      @include mq-min(tablet){
        padding: 2% 0 1% 6%;
      }
    }
    span{
      line-height: 1.5;
    }
    .content-tabla {
      color: $color-blue;
      
      .generales-data {
        display: flex;
        align-items: center;
        span{
          color: $text-primary;
        }
        .heanding-mobile {
          padding-left: 0;
          width: 60%;
        }
        .text-right {
          padding-right: 0;
          font-weight: 700;
        }
      }
    }
    .content-tabla-cabezal {
      color: $color-green;
      font-weight: 700;
      margin-bottom: 1rem;
      
      .generales-data {
        display: flex;
 
        .text-right {
          padding-right: 0;
        }
      }
    }
    
    .avatar {
      margin-bottom: 7%;
      .generales-data{
        .heanding-mobile{
          width: 70%;
        }
        &:nth-of-type(even){
          background-color: $white;
          border-bottom: 2px solid $text-primary;
          margin-bottom: 1rem;
          padding: 0 0.25rem;
        }
      }
    }
    
    @include mq-min(tablet){
      .resumen-modelo {
        padding: 0 10%;
      }
    }
    @include mq-max(mobile-big){
	    .col-md-6 {
	      padding: 0 !important;
	    }
		}
  }
  
  h2 {
    color: $text-primary;
    padding: 1rem;
  }
</style>